import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/seo'

const IndexPage = () => (
    <div>
    <SEO title="首页" keywords={[`王小二的个人网站`, `笔记`, `文章`]} />
    <section style={{
        minHeight: `100vh`,
        display: `flex`,
        justifyContent: `center`,
        flexDirection: `column`,
        alignItems: `center`,
        textAlign: `center`,
    }}>
        <h1 style={{
            fontFamily: `'Quicksand', 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;`,
            display: `block`,
            fontWeight: `300`,
            fontSize: `30px`,
            color: `#35495e`,
            letterSpacing: `1px`,
        }}>
        王小二的个人网站
      </h1>
      <h2 style={{
        fontWeight: `300`,
        fontSize: `20px`,
        color: `#526488`,
        wordSpacing: `5px`,
        paddingBottom: `5px`,
     }}>
       W2's Personal Website
      </h2>
      <div style={{
        fontFamily: `'Quicksand', 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;`,
        fontSize: `15px`,
    }}>
        <Link to="/" style={{
            marginRight: `5px`,
            color: `#35495e`,
        }}>笔记</Link>
        <Link to="/" style={{
            color: `#35495e`,
        }}>文章</Link>
      </div>
    </section>
    </div>
)

export default IndexPage
